<template>
  <common-form
    name="personal-info"
    @submit="submitData"
  >
    <pp-frame>
      <template #message>
        <h2 class="blue-grey--text">
          <p>{{ $t('Personal Information') }}</p>
        </h2>

        <div class="secondary--text">
          {{ translate(`
          This information helps us verify your insurance coverage and allows
          us to contact someone you designate in the event of an emergency.
          `) }}
        </div>
      </template>

      <v-row class="flex-grow-0">
        <v-col
          cols="12"
          sm="6"
        >
          <vuetify-date-picker
            v-model="info.dob"
            :label="`1. ${dobLabel}:`"
            :rules="[
              $validators.isValid(),
              $validators.isValidDate()
            ]"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-model="info.gender"
            :items="genderItems"
            :label="`2. ${genderLabel}:`"
            :rules="[$validators.isValid()]"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-model="info.maritalStatus"
            :items="maritalStatusItems"
            :label="`3. ${maritalStatusLabel}:`"
            :rules="[$validators.isValid()]"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="info.partnerName"
            :label="`4. ${partnerNameLabel}:`"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-select
            v-model="info.preferredcontact"
            :items="preferredcontactItems"
            :label="`5. ${preferredContactLabel}:`"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        />
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            v-model="info.feet"
            v-mask="'#'"
            :label="feetLabel"
            type="number"
            :rules="heightFeetRules"
            min="1"
            max="8"
            dense
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            v-model="info.inches"
            v-mask="'##'"
            :label="inchesLabel"
            type="number"
            :rules="heightInchesRules"
            min="1"
            max="11"
            dense
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            v-model="info.weight"
            v-mask="'###'"
            :label="weightLabel"
            type="number"
            :rules="WeightLbsRules"
            min="10"
            max="500"
            dense
          />
        </v-col>
        <v-col
          cols="6"
          sm="3"
        >
          <v-text-field
            :value="bmi"
            :label="bmiLabel"
            type="number"
            readonly
            dense
          >
            <v-tooltip
              slot="append"
              bottom
            >
              <template #activator="{ on }">
                <v-icon
                  color="primary"
                  dark
                  v-on="on"
                >
                  mdi-weight-pound
                </v-icon>
              </template>
              <table>
                <tbody>
                  <tr>
                    <td><span class="mr-2">~ 18.5</span></td>
                    <td>{{ $t('Underweight') }}</td>
                  </tr>
                  <tr>
                    <td><span class="mr-2">18.5 ~ 24.9</span></td>
                    <td>{{ $t('Normal') }}</td>
                  </tr>
                  <tr>
                    <td><span class="mr-2">25 ~ 29.9</span></td>
                    <td>{{ $t('Overweight') }}</td>
                  </tr>
                  <tr>
                    <td><span class="mr-2">30 ~</span></td>
                    <td>{{ $t('Obese') }}</td>
                  </tr>
                </tbody>
              </table>
            </v-tooltip>
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="info.emergencyName"
            :label="`6. ${emergencyNameLabel}:`"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="info.emergencyRelationship"
            :label="`7. ${emergencyRelationshipLabel}:`"
            dense
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="info.emergencyNumber"
            v-mask="'(###) ###-####'"
            :label="`8. ${emergencyNumber}:`"
            autocomplete="off"
            :rules="[!info.emergencyNumber ||
              $validators.isValidPhone()]"
            dense
          />
        </v-col>
      </v-row>

      <template #actions>
        <v-row class="ma-0">
          <v-btn to="/register/contact-info">
            <v-icon>mdi-chevron-double-left</v-icon>
            {{ $t('Back') }}
          </v-btn>

          <v-spacer />

          <v-btn
            color="primary"
            type="submit"
            :loading="updatingProfile"
          >
            {{ $t('Proceed') }}
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </pp-frame>
  </common-form>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import moment from 'moment'

import { utils, validators, events, VueI18nManager } from 'ui-common'
import { useProfile } from '@/use/profile'
import { PagePersonalInfo } from '@/constants'
import { useQuestionnaire } from '@/use/questionnaire'

const usePersonalInfo = () => {
  const info = ref({
    dob: null,
    gender: '',
    maritalStatus: '',
    partnerName: '',
    preferredcontact: '',
    feet: '',
    inches: '',
    weight: '',
    emergencyName: '',
    emergencyNumber: '',
    emergencyRelationship: ''
  })

  const heightInches = computed(() =>
    (+info.value.feet || 0) * 12 + (+info.value.inches || 0))
  const bmi = computed(() => heightInches.value &&
    ((+info.value.weight || 0) * 703 /
      heightInches.value / heightInches.value).toFixed(1))
  const translationManager = VueI18nManager.getInstance()

  const heightFeetRules = computed(() => [!info.value.feet ||
    (+info.value.feet >= 1 && +info.value.feet <= 8) ||
    translationManager.t('Please enter a value between 1 and 8')
  ])
  const heightInchesRules = computed(() => [!info.value.inches ||
    (+info.value.inches >= 0 && +info.value.inches <= 11) ||
    translationManager.t('Please enter a value between 0 and 11')
  ])
  const WeightLbsRules = computed(() => [!info.value.weight ||
    (+info.value.weight >= 10 && +info.value.weight <= 500) ||
    translationManager.t('Please enter a value between 10 and 500')
  ])

  const {
    secondaryProfile,

    updatingProfile,
    updateSecondaryProfile
  } = useProfile()

  const {
    getCompletedSections
  } = useQuestionnaire()

  const feedData = () => {
    const {
      dob = null,
      gender = '',
      maritalStatus = '',
      partnerName = '',
      preferredcontact = '',
      feet,
      inches,
      weight,
      emergencyName = '',
      emergencyNumber = '',
      emergencyRelationship = ''
    } = secondaryProfile.value

    info.value = {
      dob: dob && moment(dob).format('MM/DD/YYYY'),
      gender,
      maritalStatus,
      partnerName,
      preferredcontact,
      feet,
      inches: `${(+inches === -1 ? 0 : inches)}`,
      weight,
      emergencyName,
      emergencyNumber: validators.formatPhoneNumber(emergencyNumber),
      emergencyRelationship
    }
  }

  const submitData = async () => {
    try {
      const { dob, emergencyNumber } = info.value
      await updateSecondaryProfile({
        info: {
          ...info.value,
          bmi: `${bmi.value}`,
          dob: dob && moment(dob).format('MM/DD/YYYY'),
          emergencyNumber: validators.formatPhoneNumber(
            emergencyNumber,
            false
          ),
          lastRegSect: PagePersonalInfo
        }
      })
      await getCompletedSections()
      utils.navigate('/register')
    } catch (err) {}
  }

  return {
    info,
    bmi,
    feedData,
    submitData,

    heightFeetRules,
    heightInchesRules,
    WeightLbsRules,
    updatingProfile
  }
}

export default {
  setup: (props, context) => {
    const { root } = context

    const genderItems = [
      {
        text: 'Male',
        value: 'Male'
      },
      {
        text: 'Female',
        value: 'Female'
      }
    ]
    const maritalStatusItems = [
      {
        text: 'Married',
        value: 'Married'
      },
      {
        text: 'Single',
        value: 'Single'
      },
      {
        text: 'Life Partner',
        value: 'Life Partner'
      },
      {
        text: 'Minor',
        value: 'Minor'
      }
    ]
    const preferredcontactItems = [
      {
        text: 'paper',
        value: 'paper'
      }
    ]

    const {
      info,
      bmi,
      feedData,
      submitData,

      heightFeetRules,
      heightInchesRules,
      WeightLbsRules,
      updatingProfile
    } = usePersonalInfo()

    onMounted(() => {
      feedData()
      root.$nextTick(() => {
        events.emit('form.reset.personal-info')
      })
    })

    return {
      info,
      bmi,
      feedData,
      submitData,

      heightFeetRules,
      heightInchesRules,
      WeightLbsRules,
      updatingProfile,

      genderItems: computed(() =>
        genderItems.map(item => {
          item.text = root.$i18n.t(item.value)
          return item
        })
      ),
      maritalStatusItems: computed(() =>
        maritalStatusItems.map(item => {
          item.text = root.$i18n.t(item.value)
          return item
        })
      ),
      preferredcontactItems: computed(() =>
        preferredcontactItems.map(item => {
          item.text = root.$i18n.t(item.value)
          return item
        })
      ),

      dobLabel: computed(() =>
        root.$i18n.t('Birthday')
      ),
      genderLabel: computed(() =>
        root.$i18n.t('Gender')
      ),
      maritalStatusLabel: computed(() =>
        root.$i18n.t('Marital Status')
      ),
      partnerNameLabel: computed(() =>
        root.$i18n.t('Spouse/Partner Name')
      ),
      preferredContactLabel: computed(() =>
        root.$i18n.t('Preferred Method of Contacts')
      ),
      feetLabel: computed(() =>
        root.$i18n.t('Feet')
      ),
      inchesLabel: computed(() =>
        root.$i18n.t('Inches')
      ),
      weightLabel: computed(() =>
        root.$i18n.t('Weight')
      ),
      bmiLabel: computed(() =>
        root.$i18n.t('BMI')
      ),
      emergencyNameLabel: computed(() =>
        root.$i18n.t('Emergency Contact Name')
      ),
      emergencyRelationshipLabel: computed(() =>
        root.$i18n.t('Emergency Contact Relationship')
      ),
      emergencyNumber: computed(() =>
        root.$i18n.t('Emergency Contact Number')
      )
    }
  }
}
</script>
